<template>
    <div
        class="item filled"
    >
        <img
            :src="item.thumbnail"
            width="75"
            height="50"
            :alt="item.productCode"
        >
        <b-button
            variant="primary"
            class="fill icon x-sm"
            :aria-label="$t('page.category.compare.deleteButton')"
            @click="remove()"
        >
            <x-icon />
        </b-button>
    </div>
</template>

<script>
    import XIcon from 'vue-feather-icons/icons/XIcon';
    import { mapMutations } from 'vuex';
    export default {
        name: 'CompareItem',
        components: {
            XIcon,
        },
        props: {
            item: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        methods: {
            ...mapMutations('compare', ['removeFromCompareData']),
            remove() {
                this.removeFromCompareData(this.item.productCode);
            },
        },
    };
</script>
<style scoped lang="scss">
.items {
  gap: 16px;
  align-items: center;
  .item {
    &.filled {
      .btn {
        position: absolute;
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
        top: -1px;
        right: -1px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .btn {
    margin-top: 0;
  }
}
</style>
