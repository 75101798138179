<template>
    <div class="compare-popup">
        <div class="container sb">
            <div class="items">
                <compare-item
                    v-for="(image, productCode) in compareItems"
                    :key="`compare-${productCode}`"
                    :item="{ productCode: productCode, thumbnail: image }"
                />
                <div
                    v-for="index in availableData"
                    :key="`available-compare-${index}`"
                    class="item"
                />
            </div>
            <div class="actions d-flex">
                <b-button
                    variant="text"
                    class="stroke reset"
                    :class="{ sm: isResponsive }"
                    :aria-label="$t('page.category.compare.deleteButton')"
                    @click="reset()"
                >
                    {{ $t('page.category.compare.deleteButton') }}
                </b-button>
                <router-link
                    v-slot="{ navigate }"
                    :aria-label="$t('page.category.compare.compareButton')"
                    :to="{ name: 'Comparare', params: { code: compareCodes } }"
                    custom
                >
                    <div>
                        <b-button
                            :disabled="itemsCount == 1"
                            variant="primary"
                            class="compare-desktop fill"
                            :aria-label="$t('page.category.compare.compareButton')"
                            @click="navigate"
                        >
                            {{ $t('page.category.compare.compareButton') }}
                        </b-button>
                        <b-button
                            :disabled="itemsCount == 1"
                            variant="primary"
                            class="compare-mobile icon"
                            :aria-label="$t('page.category.compare.compareButton')"
                            @click="navigate"
                        >
                            <img
                                src="/images/compare.svg"
                                width="24"
                                height="24"
                                :alt="$t('page.category.compare.compareButton')"
                            >
                        </b-button>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex';

    import CompareItem from './CompareItem';
    export default {
        name: 'ComparePopup',
        components: {
            CompareItem,
        },
        data() {
            return {
                isResponsive: false,
            };
        },
        props: {
            compareData: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        computed: {
            availableData() {
                return 4 - Object.keys(this.compareData[this.compareCategory()]).length;
            },
            compareItems() {
                return this.compareData[this.compareCategory()];
            },
            itemsCount() {
                return Object.keys(this.compareData[this.compareCategory()]).length;
            },
            compareCodes() {
                return window.btoa(Object.keys(this.compareData[this.compareCategory()]));
            },
        },
        methods: {
            ...mapMutations('compare', ['resetCompareData']),
            reset() {
                this.resetCompareData();
            },
            compareCategory() {
                return Object.keys(this.compareData)[0];
            },
        },
        mounted() {
            this.isResponsive = window.innerWidth < 768;
        },
    };
</script>
<style scoped lang="scss">
.compare-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 0px;
  background: $backgroundGray;
  box-shadow: 0px 0px 32px rgb(19 28 33 / 10%);
  .container {
    justify-content: space-between;
    display: flex;
    padding-top: 0;
    gap: 8px;
    > div {
      &.items {
        display: flex;
        gap: 8px;
        align-items: center;
        .item {
          aspect-ratio: 3 / 2;
          height: 50px;
          border-radius: 4px;
          box-shadow: 0 0 0 1.2px $primary-25;
          position: relative;
          &.filled {
            box-shadow: 0 0 0 1.2px $primary;
          }
          @include media-breakpoint-down(sm) {
            height: 36px;
          }
        }
      }
      &.actions {
        .compare-mobile {
          display: none;
        }
        @include media-breakpoint-down(sm) {
          .compare-desktop {
            display: none;
          }
          .compare-mobile {
            display: block;
          }
        }
      }
      &:last-of-type {
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        .btn {
          margin-top: 0;
          &.reset {
            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
          @include media-breakpoint-down(sm) {
            height: 38px;
          }
        }
      }
    }
  }
}
</style>
